<template>
  <div class="part1">
    <div class='part1-1'>
      <el-table :data="reviewerAcc" stripe highlight-current-row height="100%" style="width: 100%;"
        @row-click="choiceReview">
        <el-table-column label="审查点视角" align="center">
          <el-table-column prop="name" label="审查点名称" min-width="50%" />
          <el-table-column prop="acc" label="准确率" min-width="15%" />
          <el-table-column prop="pos_acc" label="定位准确率" min-width="15%" />
          <el-table-column prop="pos_recall" label="定位召回率" min-width="15%" />
        </el-table-column>

      </el-table>
    </div>
    <div class='part1-2'>
      <el-table :data="contractRes" stripe highlight-current-row height="100%" style="width: 100%;"
        @row-click="choiceContract">
        <el-table-column :label="reviewerName" align="center">
          <el-table-column prop="name" label="合同名称" min-width="60%" />
          <el-table-column prop="acc" label="准确率" min-width="20%" />
          <el-table-column label="操作" min-width="20%">
            <template #default="scope">
              <el-button size="small" @click.enter.stop="viewContent(scope.$index, scope.row)">原文</el-button>
              <el-button size="small" @click.enter.stop="viewLable(scope.$index, scope.row)">标签</el-button>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
  </div>
  <div class='part3'>
    <el-table :data="answerDetail" height="100%" style="white-space: pre-wrap; width: 33%;  float: left;">
        <el-table-column prop="人工答案" label="人工答案" />
    </el-table>
    <el-table :data="answerDetail" height="100%" style="white-space: pre-wrap; width: 33%; float: left;">
      <el-table-column prop="机器答案" label="机器答案"/>
    </el-table>
    <el-table :data="answerDetail" height="100%" style="white-space: pre-wrap; width: 33%; float: right;">
        <el-table-column prop="答案差异" label="答案差异" />
    </el-table>
  </div>
  <el-dialog v-model="dialogVisible" :title="contentName" center width="80%" top="1vh" draggable >
    <div class="content-dialog">
      {{ contractContent }}
    </div>
  </el-dialog>
  <el-dialog v-model="dialogVisible2" :title="contentName" center width="80%" top="1vh" draggable >
    <div class="content-dialog">
      {{ contractLabel }}
    </div>
  </el-dialog>
</template>

<script >
import axios from 'axios'
import { ref } from 'vue'

export default {
  name: 'AnsComparePart',

  setup() {
    let reviewerAcc = ref()
    let reviewerContracts = ref()
    let contractRes = ref()
    let reviewerName = ref()
    let contractName = ref()
    let answerDetail = ref()
    let allAnswerDetail = ref()
    let reviewerAnswerDetail = ref()
    let dialogVisible = ref(false)
    let dialogVisible2 = ref(false)
    let allContractContent = ref()
    let allContractLabel = ref()
    let contractContent = ref()
    let contractLabel = ref()
    let contentName = ref()
    let filename = ref()
    filename.value = window.location.href.split("=")[1]
    axios.get(
      "ans/" + filename.value
    ).then(response => {
      reviewerAcc.value = response.data.part1.reviewer_acc
      reviewerName.value = reviewerAcc.value[0]['name']
      reviewerContracts.value = response.data.part2
      contractRes.value = reviewerContracts.value[reviewerName.value]

      allAnswerDetail.value = response.data.part3
      contractName.value = contractRes.value[0]['name']
      reviewerAnswerDetail.value = allAnswerDetail.value[contractName.value]
      if (typeof reviewerAnswerDetail.value === 'undefined') {
        answerDetail.value = []
      } else {
        answerDetail.value = reviewerAnswerDetail.value[reviewerName.value]
      }
      allContractContent.value = response.data.part4
      allContractLabel.value = response.data.part5
    })

    function choiceReview(record) {
      reviewerName.value = record['name']
      contractRes.value = reviewerContracts.value[reviewerName.value]
      answerDetail.value = allAnswerDetail.value[contractName.value][reviewerName.value]
    }

    function choiceContract(record) {
      contractName.value = record['name']
      reviewerAnswerDetail.value = allAnswerDetail.value[contractName.value]
      if (typeof reviewerAnswerDetail.value === 'undefined') {
        answerDetail.value = []
      } else {
        answerDetail.value = reviewerAnswerDetail.value[reviewerName.value]
      }
    }

    function viewContent(index, row) {
      contractContent.value = allContractContent.value[row['name']]
      contentName.value = row['name']
      dialogVisible.value = true
    }

    function viewLable(index, row) {
      contractLabel.value = allContractLabel.value[row['name']]
      contentName.value = row['name']
      dialogVisible2.value = true
    }

    return {
      reviewerAcc,
      reviewerContracts,
      reviewerName,
      contractRes,
      allAnswerDetail,
      contractName,
      reviewerAnswerDetail,
      answerDetail,
      allContractContent,
      dialogVisible,
      dialogVisible2,
      contractContent,
      contractLabel,
      contentName,
      choiceReview,
      choiceContract,
      viewContent,
      viewLable
    }
  }
}

</script>


<style scoped>
h1 {
  text-align: center;
}

.card-header {
  justify-content: space-between;
  align-items: center;
}

.part1 {
  height: 48%;
  margin-bottom: 2%;
}

.part1-1 {
  height: 100%;
  width: 42%;
  margin-right: 2%;
  float: left;
}

.part1-2 {
  height: 100%;
  width: 56%;
  float: left;
}

.part3 {
  height: 45%;
}

:deep(.el-table .cell) {
  white-space: pre-wrap;
}

.content-dialog {
  width: 100%;
  height: 50vh;
  white-space: pre-wrap;
  overflow: auto;
}

:deep(.el-table__body tr.current-row>td){
	color: rgb(29, 29, 29);
	background: rgb(129, 216, 207)!important;
}
</style>
