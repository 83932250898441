<template>
  <div class="header">
    <h1>审查报告（共 {{contractNum}} 份合同，{{reviewerNum}} 个审查点）</h1>
  </div>
  <div class="part">
    <AnsComparePart />
  </div>
</template>

<script>
import axios from 'axios'
import { ref } from 'vue'
import AnsComparePart from './components/AnsComparePart.vue'

export default {
  name: 'App',
  components: {
    AnsComparePart
  },
  setup() {
    let contractNum = ref()
    let reviewerNum = ref(0)
    let filename = ref()
    filename.value = window.location.href.split("=")[1]
    axios.get(
      "ans/" + filename.value
    ).then(response => {
      contractNum.value = response.data.part1.contract_num
      reviewerNum.value = response.data.part1.reviewer_acc.length
    })

    return {
      contractNum,
      reviewerNum
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#app {
  height: 100%;
}

.header {
  height: 5%;
  text-align: center;
  margin-bottom: 2%;
}

.part {
  height: 93%;
}

</style>
